import * as qz from "qz-tray";
import coreApiClient from "./apis/coreApiClient";

class PrintService {
    constructor() {
        this.initialize().catch(console.error)
    }

    public async print(printerName: string, url: any, format: string, configOptions: any = {}) {
        configOptions.colorType = "blackwhite"
        const config = qz.configs.create(printerName, configOptions);
        await qz.print(config, [{
            type: 'pixel',
            format,
            flavor: 'file',
            data: url,
        }]);
    }

    public async printImage(printerName: string, url: string, configOptions?: any) {
        await this.print(printerName, url, 'image', configOptions)
    }

    public async printPdf(printerName: string, url: string, configOptions?: any) {
        await this.print(printerName, url, 'pdf', configOptions)
    }

    private async initialize() {
        qz.security.setCertificatePromise(async (resolve, reject) => {
            try {
                let content = await coreApiClient.callRaw("print", "getDigitalCertificate")
                content = atob(content)
                resolve(content)
            } catch (e) {
                reject(e)
            }
        });
        qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        qz.security.setSignaturePromise((toSign) => {
            return async (resolve, reject) => {
                try {
                    const signed = await coreApiClient.callRaw("print", "sign", { content: toSign })
                    resolve(signed)
                } catch (e) {
                    reject(e)
                }
            }
        });
        await qz.websocket.connect()
    }
}

export default new PrintService()
