











import Vue from "vue";
import BasePrintBarcode from "./print-barcode/partials/BasePrintBarcode.vue";
export default Vue.extend({
  components: { BasePrintBarcode },
});
